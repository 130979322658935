<template>
  <div class="order_people">
    <div class="content">
      <div class="people_detail">
        <div class="title">
          用药人信息
        </div>
        <div class="people_box">
          <div class="peoples" v-for="(item,index) in peopleList.data" @click="selectPeople(index,item)"
               :key="index"  :class="{ selected:peopleIndex == index}" >
            <div class="tit">
              <span style="font-weight: bold;font-size: 14px">{{ item.name }}</span>
<!--              <span>编辑</span>-->
            </div>
            <div>
              <span>{{ item.peopleSex }}</span><span>&nbsp;{{ item.peopleAge }}</span><span>岁</span>
            </div>
            <div>
                 <span>手机号：</span> <span>{{ item.phone.slice(0,3) + '****' + item.phone.slice(7,11) }}</span>
            </div>
            <div>
                <span>身份证号：</span><span>{{ item.idNumber.slice(0,6)+ '********' + item.idNumber.slice(14,18) }}</span>
            </div>
            <div>
              <span>健康信息：</span>
              <text v-if="item.medicalHistory[0][0] != '无'">有病史、</text>
              <text v-if="item.medicalHistory[0][0] == '无'">无病史、</text>

              <text v-if="item.drugAllergy[0][0] != '无'">有药物过敏史、</text>
              <text v-if="item.drugAllergy[0][0] == '无'">无药物过敏史、</text>

              <text v-if="item.foodTouchAllergy[0][0] != '无'">有食物/接触过敏史、</text>
              <text v-if="item.foodTouchAllergy[0][0] == '无'">无食物/接触过敏史、</text>

              <text v-if="item.geneticDisorders[0][0] != '无'">有家族病史、</text>
              <text v-if="item.geneticDisorders[0][0] == '无'">无家族病史、</text>

              <text v-if="item.isSmoking[0][0] != '无'">有吸烟、</text>
              <text v-if="item.isSmoking[0][0] == '无'">无吸烟、</text>

              <text v-if="item.isWine[0][0] != '无'">有饮酒、</text>
              <text v-if="item.isWine[0][0] == '无'">无饮酒、</text>

<!--              <span v-if="item.isPregnancy[0] != 'none' ">-->
                 <text v-if="item.isPregnancy[0][0] != '无' ">有妊娠哺乳、</text>
                 <text v-if="item.isPregnancy[0][0] == '无' " >无妊娠哺乳、</text>
<!--                </span>-->
            </div>
            <img v-if="peopleIndex==index" class="img_selected" :src="invoice_selected" alt />
          </div>
          <div @click="addBtn" class="add_box">
            <i class="el-icon-plus" style="font-size: 30px;font-weight: bold"></i>
            <div style="font-size: 14px;margin-top: 15px">
              添加用药人
            </div>
          </div>
        </div>
      </div>
      <div v-if="isAddPeople" class="add_people">
        <el-form :model="personalForm.data" :rules="rules" ref="addForms">
          <el-form-item label="姓名：" :label-width="formLabelWidth" prop="name">
            <el-input v-model="personalForm.data.name" autocomplete="off" clearable></el-input>
          </el-form-item>
          <el-form-item label="身份证号：" :label-width="formLabelWidth" prop="id">
            <el-input v-model="personalForm.data.id" @input="validAge(personalForm.data.id)" autocomplete="off"  clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号：" :label-width="formLabelWidth" prop="phone">
            <el-input v-model="personalForm.data.phone" autocomplete="off" clearable></el-input>
          </el-form-item>
          <el-form-item label="关系：" :label-width="formLabelWidth" prop="region">
            <el-select v-model="personalForm.data.region" placeholder="请选择与用药人关系" clearable>
              <el-option label="本人" value="本人"></el-option>
              <el-option label="父母" value="父母"></el-option>
              <el-option label="子女" value="子女"></el-option>
              <el-option label="配偶" value="配偶"></el-option>
              <el-option label="朋友" value="朋友"></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div style="text-align: left;font-size: 14px;margin-left: 45px">健康信息：</div>
        <div class="illness_list">
          <div class="illness_box">
            <div class="illness_title">
              过往病史:
            </div>
            <div class="illness">
              <button class="illness_btn" :class="handleBtnClass(0,0)" @click="handleIllnessClick(0,0)">无</button>
              <button class="illness_btn" :class="handleBtnClass(0,1)" @click="handleIllnessClick(0,1)">糖尿病</button>
              <button class="illness_btn" :class="handleBtnClass(0,2)" @click="handleIllnessClick(0,2)">高血压</button>
              <button class="illness_btn" :class="handleBtnClass(0,3)" @click="handleIllnessClick(0,3)">心脑血管疾病</button>
              <button class="illness_btn" :class="handleBtnClass(0,4)" @click="handleIllnessClick(0,4)">高血脂</button>
              <button class="illness_btn" :class="handleBtnClass(0,5)" @click="handleIllnessClick(0,5)">肝炎</button>
              <button class="illness_btn" :class="handleBtnClass(0,6)" @click="handleIllnessClick(0,6)">高尿酸血症</button>
              <button class="illness_btn" :class="handleBtnClass(0,7)" @click="handleIllnessClick(0,7)">支气管哮喘</button>
              <button class="illness_btn" :class="handleBtnClass(0,8)" @click="handleIllnessClick(0,8)">结核病</button>
              <button class="illness_btn" :class="handleBtnClass(0,9)" @click="handleIllnessClick(0,9)">恶性肿瘤</button>
              <button class="illness_btn" :class="handleBtnClass(0,10)" @click="handleIllnessClick(0,10)">肝病</button>
              <button class="illness_btn" :class="handleBtnClass(0,11)" @click="handleIllnessClick(0,11)">肾病</button>
            </div>
          </div>
          <div class="illness_box">
            <div class="illness_title">
              药物过敏史:
            </div>
            <div class="illness">
              <button class="illness_btn" :class="handleBtnClass(1,0)" @click="handleIllnessClick(1,0)">无</button>
              <button class="illness_btn" :class="handleBtnClass(1,1)" @click="handleIllnessClick(1,1)">青霉素</button>
              <button class="illness_btn" :class="handleBtnClass(1,2)" @click="handleIllnessClick(1,2)">头孢类抗生素</button>
              <button class="illness_btn" :class="handleBtnClass(1,3)" @click="handleIllnessClick(1,3)">破伤风抗毒素(TAT)</button>
              <button class="illness_btn" :class="handleBtnClass(1,4)" @click="handleIllnessClick(1,4)">普鲁卡因</button>
              <button class="illness_btn" :class="handleBtnClass(1,5)" @click="handleIllnessClick(1,5)">地卡因</button>
              <button class="illness_btn" :class="handleBtnClass(1,6)" @click="handleIllnessClick(1,6)">磺胺类药物</button>
              <button class="illness_btn" :class="handleBtnClass(1,7)" @click="handleIllnessClick(1,7)">维生素B1</button>
              <button class="illness_btn" :class="handleBtnClass(1,8)" @click="handleIllnessClick(1,8)">泛影葡胺</button>
              <button class="illness_btn" :class="handleBtnClass(1,9)" @click="handleIllnessClick(1,9)">阿司匹林</button>
              <button class="illness_btn" :class="handleBtnClass(1,10)" @click="handleIllnessClick(1,10)">链霉素</button>
              <button class="illness_btn" :class="handleBtnClass(1,11)" @click="handleIllnessClick(1,11)">安定</button>
              <button class="illness_btn" :class="handleBtnClass(1,12)" @click="handleIllnessClick(1,12)">去痛片</button>
            </div>
          </div>
          <div class="illness_box">
            <div class="illness_title">
              食物/接触过敏史:
            </div>
            <div class="illness">
              <button class="illness_btn" :class="handleBtnClass(2,0)" @click="handleIllnessClick(2,0)">无</button>
              <button class="illness_btn" :class="handleBtnClass(2,1)" @click="handleIllnessClick(2,1)">鸡蛋</button>
              <button class="illness_btn" :class="handleBtnClass(2,2)" @click="handleIllnessClick(2,2)">面粉</button>
              <button class="illness_btn" :class="handleBtnClass(2,3)" @click="handleIllnessClick(2,3)">坚果</button>
              <button class="illness_btn" :class="handleBtnClass(2,4)" @click="handleIllnessClick(2,4)">海鲜</button>
              <button class="illness_btn" :class="handleBtnClass(2,5)" @click="handleIllnessClick(2,5)">豆制品</button>
              <button class="illness_btn" :class="handleBtnClass(2,6)" @click="handleIllnessClick(2,6)">花粉</button>
              <button class="illness_btn" :class="handleBtnClass(2,7)" @click="handleIllnessClick(2,7)">动物毛发</button>
              <button class="illness_btn" :class="handleBtnClass(2,8)" @click="handleIllnessClick(2,8)">酒精</button>
              <button class="illness_btn" :class="handleBtnClass(2,9)" @click="handleIllnessClick(2,9)">牛奶</button>
              <button class="illness_btn" :class="handleBtnClass(2,10)" @click="handleIllnessClick(2,10)">粉尘</button>
              <button class="illness_btn" :class="handleBtnClass(2,11)" @click="handleIllnessClick(2,11)">螨虫</button>
            </div>
          </div>
          <div class="illness_box">
            <div class="illness_title">
              家族遗传病史:
            </div>
            <div class="illness">
              <button class="illness_btn" :class="handleBtnClass(3,0)" @click="handleIllnessClick(3,0)">无</button>
              <button class="illness_btn" :class="handleBtnClass(3,1)" @click="handleIllnessClick(3,1)">高血压</button>
              <button class="illness_btn" :class="handleBtnClass(3,2)" @click="handleIllnessClick(3,2)">糖尿病</button>
              <button class="illness_btn" :class="handleBtnClass(3,3)" @click="handleIllnessClick(3,3)">脑梗</button>
              <button class="illness_btn" :class="handleBtnClass(3,4)" @click="handleIllnessClick(3,4)">脑出血</button>
              <button class="illness_btn" :class="handleBtnClass(3,5)" @click="handleIllnessClick(3,5)">癌症</button>
              <button class="illness_btn" :class="handleBtnClass(3,6)" @click="handleIllnessClick(3,6)">过敏性疾病</button>
              <button class="illness_btn" :class="handleBtnClass(3,7)" @click="handleIllnessClick(3,7)">哮喘</button>
              <button class="illness_btn" :class="handleBtnClass(3,8)" @click="handleIllnessClick(3,8)">癫痫</button>
              <button class="illness_btn" :class="handleBtnClass(3,9)" @click="handleIllnessClick(3,9)">白癜风</button>
              <button class="illness_btn" :class="handleBtnClass(3,10)" @click="handleIllnessClick(3,10)">近视</button>
              <button class="illness_btn" :class="handleBtnClass(3,11)" @click="handleIllnessClick(3,11)">精神疾病</button>
              <button class="illness_btn" :class="handleBtnClass(3,12)" @click="handleIllnessClick(3,12)">超重</button>
              <button class="illness_btn" :class="handleBtnClass(3,13)" @click="handleIllnessClick(3,13)">骨质疏松</button>
              <button class="illness_btn" :class="handleBtnClass(3,14)" @click="handleIllnessClick(3,14)">冠心病</button>
            </div>
          </div>
          <div class="illness_box">
            <div class="illness_title">
              吸烟:
            </div>
            <div class="illness">
              <button class="illness_btn" :class="handleBtnClass(4,0)" @click="handleIllnessClick(4,0)">无</button>
              <button class="illness_btn" :class="handleBtnClass(4,1)" @click="handleIllnessClick(4,1)">有</button>
            </div>
          </div>
          <div class="illness_box">
            <div class="illness_title">
              饮酒:
            </div>
            <div class="illness">
              <button class="illness_btn" :class="handleBtnClass(5,0)" @click="handleIllnessClick(5,0)">无</button>
              <button class="illness_btn" :class="handleBtnClass(5,1)" @click="handleIllnessClick(5,1)">有</button>
            </div>
          </div>
          <div class="illness_box">
            <div class="illness_title">
              妊娠哺乳:
            </div>
            <div class="illness">
              <button class="illness_btn" :class="handleBtnClass(6,0)" @click="handleIllnessClick(6,0)">无</button>
              <button class="illness_btn" :class="handleBtnClass(6,1)" @click="handleIllnessClick(6,1)">备孕中</button>
              <button class="illness_btn" :class="handleBtnClass(6,2)" @click="handleIllnessClick(6,2)">怀孕中</button>
              <button class="illness_btn" :class="handleBtnClass(6,3)" @click="handleIllnessClick(6,3)">正在哺乳</button>
            </div>
          </div>
        </div>
        <el-form-item>
          <el-button :disabled="isDisabled" v-if="!isEdit"  style="border: none;" class="colors" type="primary" @click="addConfirm(0)">确认新增</el-button>
          <el-button :disabled="isDisabled" v-if="isEdit"  style="border: none;" class="colors" type="primary" @click="addConfirm(1)">确认编辑</el-button>
        </el-form-item>
      </div>
      <div class="begin_to_treat">
        <el-form :model="beginToTreat.data" :rules="treatRules" ref="treatForms">
          <el-form-item label="1.请填写线下已确诊疾病（用于医生诊断开方）" :label-width="formLabelWidth" prop="desc">
            <el-input type="textarea" placeholder="请填写线下已确诊的疾病" v-model="beginToTreat.data.desc" clearable></el-input>
          </el-form-item>
          <el-form-item label="2. 您是否服用过该药品且无相关禁忌症？" prop="resource">
            <el-radio-group v-model="beginToTreat.data.resource" @change="haveNothing(beginToTreat.data.resource)">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="3. 您是否对该药品过敏？" prop="allergies">
            <el-radio-group v-model="beginToTreat.data.allergies">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="4. 有无不良反应？" prop="sideeffect">
            <el-radio-group v-model="beginToTreat.data.sideeffect">
              <el-radio label="1" class="labels">有</el-radio>
              <el-radio label="0" class="labels">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="上传病例资料（用于辅助诊断,最多上传5张图片）" >
            <el-upload
                :file-list="imgList"
                :action="uploadImgUrl"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :data="upload_data"
                :on-success="uploadImg"
                limit="5"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog v-model="dialogVisible" lock-scroll="false" top="10vh" width="60%">
              <div style="overflow: auto; width: 100%; display: flex; justify-content: center;">
                <img :src="dialogImageUrl" alt="" style="max-height: 700px;">
              </div>
            </el-dialog>
          </el-form-item>
          <el-form-item label="" prop="understand">
            <el-checkbox v-model="beginToTreat.data.understand" label="我已知晓，在药品使用前需确保阅读用药说明书和用药禁忌，并遵医嘱使用" size="small"></el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button v-if="!isEditForm" :disabled="isDisabled" style="border: none;" class="colors" type="primary" @click="submitForm(0)">免费问诊并开方</el-button>
            <el-button v-if="isEditForm" :disabled="isDisabled" style="border: none;" class="colors" type="primary" @click="submitForm(1)">确认编辑</el-button>
            <el-button @click="resetForm" class="clear">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--年龄小于六岁弹出-->
    <el-dialog width="500px" title="提示!" :close-on-click-modal="false" v-model="dialogFormVisible">
      <div style="margin-bottom: 50px;font-size: 15px">
        <span>当前用药人年龄小于6岁，建议您到线下医院问诊</span>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getCurrentInstance, onMounted, reactive, ref} from "vue";
import {apiUrl} from "../../utils/config";
import {ElMessage} from "element-plus";
import {useRouter} from "vue-router";
import IdentityCodeValid from "../../utils/idCardValidate";

export default {
  name: "orderAddPeople",
  setup(){
    const {proxy} = getCurrentInstance()
    const router = useRouter();
    const dialogImageUrl = ref("");
    const diagnosticId = ref();
    const dialogVisible = ref(false);
    const upload_data = reactive({
      source: "diagnosticInfo",
    });
    const ioss = ref()
    // 是否提交
    const isEditForm = ref(false)
    // 按钮禁用
    const isDisabled = ref(false)
    const dialogFormVisible = ref(false)
    // 是否编辑
    const isEdit = ref(false)
    // 选中的图片
    const invoice_selected = require("../../assets/buy/invoice_selected.png");
    // 是否添加用药人
    const isAddPeople = ref(false)
    // 正在编辑的用药人id
    const editId = ref()
    // 用户名
    const memberName = ref()
    // 返回值
    const bindId = ref()
    //图片上传路径
    const uploadImgUrl = apiUrl + "/v3/oss/common/upload";
    // 用药人list
    const peopleList = reactive({data: []});
    // 图片list
    const imgList = ref([]);
    // 新增表单
    const addForms = ref(null);
    // 复方开诊表单
    const treatForms = ref(null);
    const upLoadFile = reactive({ data: [] }); //用于传给后端的文件列表
    // 个人信息
    const formLabelWidth = "105px";
    // 个人信息
    const personalForm = reactive({data:{}})
    // 复方开诊
    const beginToTreat = reactive({data:{
        resource: "1",
        sideeffect: "0",
        allergies: "0"
      }})
    // 健康信息
    const illnessList =  reactive({data: [ [0],[0],[0],[0],[0],[0],[0] ] });
    // 用药人对应的索引
    const peopleIndex = ref(0);
    const ids = ref(false)
    const isId = ref(false) //身份证号是否重复判断
    // 选择不同用药人,
    const selectPeople = (index,item) => {
      peopleIndex.value = index
    };
    // 新增用药人按钮
    const addBtn = () => {
      illnessList.data = [ [0],[0],[0],[0],[0],[0],[0] ]
      // addForms.value.resetFields() // 表单验证重置
      personalForm.data = {}
      isEdit.value = false
      isAddPeople.value = true
    };
    // 身份证号表单验证,
    const validIdNumber = (rule, value, callback) => {
      setTimeout(() => {
        let data = value.toUpperCase() //转换成大写
        ids.value = IdentityCodeValid( data )//验证身份证号
        if (value === '') {
          callback(new Error('请输入身份证号'));
        } else if (!ids.value) {
          callback(new Error('请输入正确的身份证号'));
        } else if (isId.value && !isEdit.value) {
          callback(new Error('用药人身份证号重复'));
        } else {
          callback();
        }
      },300)
    };
    // 个人信息验证
    const rules = {
      name: [
        {required: true, message: "请输用药人姓名", trigger: "change"},
        {min: 2, max: 10, message: "请输入2~10个字符", trigger: "change"}
      ],
      id: [
        { validator: validIdNumber, required: true, trigger: "change"},
        // {
        //   pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{4}$/,
        //   message: "请输入正确的身份证号",
        //   trigger: "change"
        // }
      ],
      phone: [
        {required: true, message: "请输入手机号", trigger: "change"},
        {
          pattern: /^((\+?86)|(\(\+86\)))?(1[3-9]\d{9}$)|(\d{4}-)\d{6,8}$/,
          message: "请输入正确的电话号码",
          trigger: "change"
        }
      ],
    };
    // 个人信息验证
    const treatRules = {
      desc: [
        { required: true, message: '请填写已确诊的疾病', trigger: 'blur' }
      ],
      resource: [
        { required: true, message: '请选择是否有相关禁忌症', trigger: 'change' }
      ],
      allergies: [
        { required: true, message: '请选择是否对该药品过敏', trigger: 'change' }
      ],
      sideeffect: [
        { required: true, message: '请选择有无不良反应', trigger: 'change' }
      ],
      understand: [
        { required: true, message: '请阅读注意事项', trigger: 'change' }
      ],
    };
    //获取用药人列表
    const getPeoplelist = () => {
      proxy.$get("v3/member/front/memberMedicineUser/list").then(res => {
        if (res.state == 200) {
          // let indexs = res.data.findIndex((value, index) => )
          // console.log('res.data',res.data)
          res.data.forEach((item,index) => {
            let selName = localStorage.getItem('selName')
            if(item.name == selName) {
              peopleIndex.value = index
              localStorage.removeItem('selName')
            }
            //判断年领
            let org_birthday = item.idNumber.substring(6, 14);
            let birthday = org_birthday.substring(0, 4) + "-" + org_birthday.substring(4, 6) + "-" + org_birthday.substring(6, 8);
            let birthdays = new Date(birthday.replace(/-/g, "/"));
            let d = new Date();
            item.peopleAge = d.getFullYear() - birthdays.getFullYear() - (d.getMonth() < birthdays.getMonth() ||
            (d.getMonth() == birthdays.getMonth() && d.getDate() < birthdays.getDate()) ? 1 : 0);

            // 手机号,身份证号加密
            // let newPhone = item.phone.slice(0,3) + '****' + item.phone.slice(7,11)
            // let NewIdNumber = item.idNumber.slice(0,6)+ '********' + item.idNumber.slice(14,18)
            // item.push(newPhone)
            // 判断性别
            let org_gender = item.idNumber.substring(16, 17);
            item.peopleSex = org_gender % 2 == 1 ? '男' : '女';
          })
          peopleList.data = res.data
        }
      })
    }
    // 编辑用药人信息
    const edit = (item) => {
      isAddPeople.value = true
      isEdit.value = true
      isDisabled.value = false
      editId.value = item.id
      // 过往病史
      for (let i = 0; i<item.medicalHistory.length; i++){
        if(item.medicalHistory[i] === '无过往病史'){
          item.medicalHistory[i] = 0
        } else if(item.medicalHistory[i] === '糖尿病'){
          item.medicalHistory[i] = 1
        } else if(item.medicalHistory[i] === '高血压'){
          item.medicalHistory[i] = 2
        } else if(item.medicalHistory[i] === '心脑血管疾病'){
          item.medicalHistory[i] = 3
        } else if(item.medicalHistory[i] === '高血脂'){
          item.medicalHistory[i] = 4
        } else if(item.medicalHistory[i] === '肝炎'){
          item.medicalHistory[i] = 5
        } else if(item.medicalHistory[i] === '高尿酸血症'){
          item.medicalHistory[i] = 6
        } else if(item.medicalHistory[i] === '支气管哮喘'){
          item.medicalHistory[i] = 7
        } else if(item.medicalHistory[i] === '结核病'){
          item.medicalHistory[i] = 8
        } else if(item.medicalHistory[i] === '恶性肿瘤'){
          item.medicalHistory[i] = 9
        } else if(item.medicalHistory[i] === '肝病'){
          item.medicalHistory[i] = 10
        } else if(item.medicalHistory[i] === '肾病'){
          item.medicalHistory[i] = 11
        }
      }
      // 药物过敏史
      for (let i = 0; i<item.drugAllergy.length; i++){
        if(item.drugAllergy[i] === '无过药物过敏史'){
          item.drugAllergy[i] = 0
        } else if(item.drugAllergy[i] === '青霉素'){
          item.drugAllergy[i] = 1
        } else if(item.drugAllergy[i] === '头孢类抗生素'){
          item.drugAllergy[i] = 2
        } else if(item.drugAllergy[i] === '破伤风抗毒素(TAT)'){
          item.drugAllergy[i] = 3
        } else if(item.drugAllergy[i] === '普鲁卡因'){
          item.drugAllergy[i] = 4
        } else if(item.drugAllergy[i] === '地卡因'){
          item.drugAllergy[i] = 5
        } else if(item.drugAllergy[i] === '磺胺类药物'){
          item.drugAllergy[i] = 6
        } else if(item.drugAllergy[i] === '维生素B1'){
          item.drugAllergy[i] = 7
        } else if(item.drugAllergy[i] === '泛影葡胺'){
          item.drugAllergy[i] = 8
        } else if(item.drugAllergy[i] === '阿司匹林'){
          item.drugAllergy[i] = 9
        } else if(item.drugAllergy[i] === '链霉素'){
          item.drugAllergy[i] = 10
        } else if(item.drugAllergy[i] === '安定'){
          item.drugAllergy[i] = 11
        } else if(item.drugAllergy[i] === '去痛片'){
          item.drugAllergy[i] = 12
        }
      }
      // 食物/接触过敏史
      for (let i = 0; i<item.foodTouchAllergy.length; i++){
        if(item.foodTouchAllergy[i] === '无食物/接触过敏史'){
          item.foodTouchAllergy[i] = 0
        } else if(item.foodTouchAllergy[i] === '鸡蛋'){
          item.foodTouchAllergy[i] = 1
        } else if(item.foodTouchAllergy[i] === '面粉'){
          item.foodTouchAllergy[i] = 2
        } else if(item.foodTouchAllergy[i] === '坚果'){
          item.foodTouchAllergy[i] = 3
        } else if(item.foodTouchAllergy[i] === '海鲜'){
          item.foodTouchAllergy[i] = 4
        } else if(item.foodTouchAllergy[i] === '豆制品'){
          item.foodTouchAllergy[i] = 5
        } else if(item.foodTouchAllergy[i] === '花粉'){
          item.foodTouchAllergy[i] = 6
        } else if(item.foodTouchAllergy[i] === '动物毛发'){
          item.foodTouchAllergy[i] = 7
        } else if(item.foodTouchAllergy[i] === '酒精'){
          item.foodTouchAllergy[i] = 8
        } else if(item.foodTouchAllergy[i] === '牛奶'){
          item.foodTouchAllergy[i] = 9
        } else if(item.foodTouchAllergy[i] === '粉尘'){
          item.foodTouchAllergy[i] = 10
        } else if(item.foodTouchAllergy[i] === '螨虫'){
          item.foodTouchAllergy[i] = 11
        }
      }
      // 家族遗传病史
      for (let i = 0; i<item.geneticDisorders.length; i++){
        if(item.geneticDisorders[i] === '无家族遗传病史'){
          item.geneticDisorders[i] = 0
        } else if(item.geneticDisorders[i] === '高血压'){
          item.geneticDisorders[i] = 1
        } else if(item.geneticDisorders[i] === '糖尿病'){
          item.geneticDisorders[i] = 2
        } else if(item.geneticDisorders[i] === '脑梗'){
          item.geneticDisorders[i] = 3
        } else if(item.geneticDisorders[i] === '脑出血'){
          item.geneticDisorders[i] = 4
        } else if(item.geneticDisorders[i] === '癌症'){
          item.geneticDisorders[i] = 5
        } else if(item.geneticDisorders[i] === '过敏性疾病'){
          item.geneticDisorders[i] = 6
        } else if(item.geneticDisorders[i] === '哮喘'){
          item.geneticDisorders[i] = 7
        } else if(item.geneticDisorders[i] === '癫痫'){
          item.geneticDisorders[i] = 8
        } else if(item.geneticDisorders[i] === '白癜风'){
          item.geneticDisorders[i] = 9
        } else if(item.geneticDisorders[i] === '近视'){
          item.geneticDisorders[i] = 10
        } else if(item.geneticDisorders[i] === '精神疾病'){
          item.geneticDisorders[i] = 11
        } else if(item.geneticDisorders[i] === '超重'){
          item.geneticDisorders[i] = 12
        } else if(item.geneticDisorders[i] === '骨质疏松'){
          item.geneticDisorders[i] = 13
        } else if(item.geneticDisorders[i] === '冠心病'){
          item.geneticDisorders[i] = 14
        }
      }
      // 吸烟
      for (let i = 0; i<item.isSmoking.length; i++){
        if(item.isSmoking[i] === '无吸烟'){
          item.isSmoking[i] = 0
        } else if(item.isSmoking[i] === '有吸烟'){
          item.isSmoking[i] = 1
        }
      }
      // 饮酒
      for (let i = 0; i<item.isWine.length; i++){
        if(item.isWine[i] === '无饮酒'){
          item.isWine[i] = 0
        } else if(item.isWine[i] === '有饮酒'){
          item.isWine[i] = 1
        }
      }
      // 饮酒
      for (let i = 0; i<item.isPregnancy.length; i++){
        if(item.isPregnancy[i] === '无妊娠哺乳'){
          item.isPregnancy[i] = 0
        } else if(item.isPregnancy[i] === '备孕中'){
          item.isPregnancy[i] = 1
        } else if(item.isPregnancy[i] === '怀孕中'){
          item.isPregnancy[i] = 2
        } else if(item.isPregnancy[i] === '正在哺乳'){
          item.isPregnancy[i] = 3
        }
      }
      debugger
      personalForm.data.name = item.name
      personalForm.data.id = item.idNumber
      personalForm.data.phone = item.phone
      personalForm.data.region = item.relation
      illnessList.data[0] = item.medicalHistory
      illnessList.data[1] = item.drugAllergy
      illnessList.data[2] = item.foodTouchAllergy
      illnessList.data[3] = item.geneticDisorders
      illnessList.data[4] = item.isSmoking
      illnessList.data[5] = item.isWine
      illnessList.data[6] = item.isPregnancy
    }
    // 验证年龄是否小于六岁
    const validAge = (value) => {
      let data = value.toUpperCase() //转换成大写
      ids.value = IdentityCodeValid( data )//得到 false
      if(ids.value ){
        //判断年领
        let org_birthday = value.substring(6, 14);
        let birthday = org_birthday.substring(0, 4) + "-" + org_birthday.substring(4, 6) + "-" + org_birthday.substring(6, 8);
        let birthdays = new Date(birthday.replace(/-/g, "/"));
        let d = new Date();
        let peopleAge = d.getFullYear() - birthdays.getFullYear() - (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() == birthdays.getMonth() && d.getDate() < birthdays.getDate()) ? 1 : 0);
        if (peopleAge < 6) {
          isDisabled.value = true
          dialogFormVisible.value = true
        }else {
          isDisabled.value = false
        }
        var param = {
          idNumber: value,
          isAdd: 0
        };
        var url = "v3/member/front/memberMedicineUser/add";
        proxy.$post(url, param).then(res => {
          if (res.state == 200) {
              isDisabled.value = true
            isId.value = true
            // ElMessage.error(res.msg);
          }else {
            isDisabled.value = false
            isId.value = false
          }
        })
      }
    }
    // 时间转化
    const formatTen = (num) => {
      return num > 9 ? (num + "") : ("0" + num);
    }
    const formatDate = (date) => {
      var date = new Date(date)
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();
      return year + "-" + formatTen(month) + "-" + formatTen(day) + " " + formatTen(hour) + ":" + formatTen(minute) + ":" + formatTen(second);
    }
    // 新增,编辑用药人
    const addConfirm = (value) => {
      let time = new Date()
      let date = formatDate(time)
      addForms.value.validate(valid => {
        if (valid) {
          var param = {
              isAdd: 1,
              idNumber: personalForm.data.id.trim(),
              name: personalForm.data.name.trim(),
              phone: personalForm.data.phone.trim(),
              relation: personalForm.data.region,
              creatBy: memberName.value,
              createTime: date,
              medicalHistory: illnessList.data[0].toString(),
              drugAllergy: illnessList.data[1].toString(),
              foodTouchAllergy: illnessList.data[2].toString(),
              geneticDisorders: illnessList.data[3].toString(),
              isWine: illnessList.data[4].toString(),
              isSmoking: illnessList.data[5].toString(),
              isPregnancy: illnessList.data[6].toString(),
            };

          if(value === 0){
            var url = "v3/member/front/memberMedicineUser/add";
          } else if(value === 1) {
            var url = "v3/member/front/memberMedicineUser/edit";
            param.id = editId.value
          }
          proxy.$post(url, param).then(res => {
              if (res.state == 200) {
                ElMessage.success(res.msg);
                illnessList.data = [ [0],[0],[0],[0],[0],[0],[0] ]
                // isAddPeople.value = false
                // isEdit.value = false
                addForms.value.resetFields() // 表单验证重置
                getPeoplelist()
              }
            })
        } else {
          return false;
        }
      });
    };
    // 有无相关禁忌症
    const haveNothing = (value) => {
      if(value === "0"){
        open()
      }
    };
    // 禁忌症弹框
    const open = () => {
      alert('为了您的用药安全,建议您到线下医院问诊', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          console.log('点击确定后...')
        }
      });
    }
    //上传图片
    const uploadImg = (response) => {
      if (response.state == 200) {
        upLoadFile.data.push(response.data.path);
      }
    };
    // 删除图片
    const handleRemove = (file) => {
      if (file.status == 'success') {
        upLoadFile.data = upLoadFile.data.filter(item => item != file.response.data.path)
      }
    };
    // 图片预览
    const handlePictureCardPreview = (file) => {
      dialogImageUrl.value = file.url;
      dialogVisible.value = true;
    };
    // 表单刷新
    const resetForm = () => {
      treatForms.value.resetFields() // 表单验证重置
    }
    // 提交表单
    const submitForm = (value) => {
      let id = peopleList.data[peopleIndex.value].id
      bindId.value = localStorage.getItem("bindId");
      if(!beginToTreat.data.understand) {
        alert('您当前的健康状况不适合预约所选药品,为了您的用药安全建议您到线下医院问诊', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            console.log('点击确定后...')
          }
        });
        return;
      }
      treatForms.value.validate(valid => {
        if(valid) {
            let param = {
              medicineUserId: id,
              diseaseDiagnosis: beginToTreat.data.desc,
              isTakeTabu: beginToTreat.data.resource,
              isAllergy: beginToTreat.data.allergies,
              isADR: beginToTreat.data.sideeffect,
              caseImgList: upLoadFile.data.join(","), //上传凭证图片,多个图片用英文逗号隔开
            }
            if (value == 0){
              // 新增
              var url = 'v3/member/front/memberMedicineUser/diagnostic'
            } else if(value == 1) {
              if(bindId.value) {
                param.bindId = bindId.value
              }
              // 修改
              var url = 'v3/member/front/memberMedicineUser/editDiagnostic'
              param.DiagnosticId = diagnosticId.value
            }
            proxy.$post(url, param).then(res => {
              if (res.state == 200) {
                localStorage.setItem("bindId",res.data.bindId);
                localStorage.setItem("peopleId",res.data.medicineUserId);
                treatForms.value.resetFields() // 表单验证重置
                router.go(-1)
                // router.replace({ path: `/buy/confirm`, query: { bindId: res.data } })
              }
            })


        } else {
          return false
        }
      })
    }
    // 控制btn的class
    const handleBtnClass = (illnessIndex,value) => {
      return illnessList.data[illnessIndex].findIndex((_) => _ === value) > -1 ? 'illness_btn_selected' : 'illness_btn'
    }
    // 健康状况的多选单选
    const handleIllnessClick = (illnessIndex,value) => {
      const index = illnessList.data[illnessIndex].findIndex((_) => _ === value)
      const first =illnessList.data[illnessIndex].findIndex((_) => _ === 0)
      if (value === 0) {
        //选择 "无" 时,清空列表
        if (first > -1) {
          const arr = illnessList.data[illnessIndex].filter(item => item === 0);
          illnessList.data[illnessIndex] = arr
        }else {
          const arr = illnessList.data[illnessIndex].filter(item => item === 0);
          illnessList.data[illnessIndex] = arr
          illnessList.data[illnessIndex].push(value)
        }
      } else {
        if( illnessIndex === 6) {
          // 如果已经选中，则取消选中
          if (index > -1){
            illnessList.data[illnessIndex].splice(index, 1)
            illnessList.data[illnessIndex].push(0)
          } else {
            // 如果未选中，则选中
            if ( first > -1) {
              illnessList.data[illnessIndex].splice(first,1)
              illnessList.data[illnessIndex].push(value)
            }else {
              let ios = illnessList.data[illnessIndex].filter(item => item === 0)
              illnessList.data[illnessIndex] = ios
              //把其他的删掉,把新选中的添加进去
              illnessList.data[illnessIndex].push(value)
            }
          }
        } else {
          // 如果已经选中，则取消选中
          if (index > -1){
            illnessList.data[illnessIndex].splice(index, 1)
            if (illnessList.data[illnessIndex].length === 0) {
              illnessList.data[illnessIndex].push(0)
            }
          } else {
            // 如果未选中，则选中
            if ( first > -1) {
              illnessList.data[illnessIndex].splice(first,1)
              illnessList.data[illnessIndex].push(value)
            }else {
              illnessList.data[illnessIndex].push(value)
            }
          }
        }
      }
    }
    //初始化数据
    const getInitData = () => {
      proxy.$get('/v3/member/front/member/memberInfo').then(res => {
        if (res.state == 200) {
          memberName.value = res.data.memberName
        }
      })
    }
    //查询问诊相关信息
    const getInquiryDate = () => {
      bindId.value = localStorage.getItem("bindId");
      if(bindId.value) {
        // localStorage.removeItem("bindId");
        let param = {
          bindId: bindId.value,
        }
        proxy.$get('v3/member/front/memberMedicineUser/getDiagnostic',param).then(res => {
          if (res.state == 200) {
            // 编辑用药人信息
            isEditForm.value = true
            // edit(res.data.medicineUser)

            ioss.value = res.data.medicineUser.name
            getPeoplelist()
            // let indexs = res.data.medicineUser
            // console.log('index111111',indexs)
            // console.log('personalForm.data.name',personalForm.data.name)
            // console.log('res.data.medicineUser',res.data.medicineUser)
            // 开方问诊
            beginToTreat.data.desc = res.data.memberDiagnosisInfo.diseaseDiagnosis
            beginToTreat.data.resource = res.data.memberDiagnosisInfo.isTakeTabu.toString()
            beginToTreat.data.allergies = res.data.memberDiagnosisInfo.isAllergy.toString()
            beginToTreat.data.sideeffect = res.data.memberDiagnosisInfo.isADR.toString()
            diagnosticId.value = res.data.memberDiagnosisInfo.id
            // 图片回显
            if (res.data.memberDiagnosisInfo.caseImgList != '') {
              let urlStr = res.data.memberDiagnosisInfo.caseImgList.split(",");
              upLoadFile.data.push(urlStr)
              urlStr.forEach(item => {
                let obj = new Object();
                obj.url = item;
                imgList.value.push(obj);
              });
            }
          }
        })
      }else {
        console.log('没有bindId')
      }
    }

    onMounted(() => {
      getInitData()
      bindId.value = localStorage.getItem("bindId");
      if (bindId.value){
        getInquiryDate()
      } else {
        getPeoplelist()
      }
    })

    return {
      getInquiryDate,
      handlePictureCardPreview,
      uploadImg,
      handleRemove,
      validIdNumber,
      handleBtnClass,
      handleIllnessClick,
      addConfirm,
      resetForm,
      submitForm,
      haveNothing,
      open,
      getPeoplelist,
      formatDate,
      formatTen,
      edit,
      validAge,
      addBtn,
      selectPeople,
      addForms,
      isEditForm,
      router,
      editId,
      isAddPeople,
      isEdit,
      peopleIndex,
      memberName,
      peopleList,
      imgList,
      upLoadFile,
      dialogImageUrl,
      dialogVisible,
      treatForms,
      formLabelWidth,
      personalForm,
      rules,
      illnessList,
      beginToTreat,
      treatRules,
      uploadImgUrl,
      upload_data,
      isDisabled,
      dialogFormVisible,
      invoice_selected,
      bindId,
      diagnosticId,
      ioss,
      ids,
      isId,
    };
  }
}
</script>

<style lang="scss">
@import "../../style/base.scss";
</style>
<style lang="scss" scoped>

 .order_people {
   width: 100%;
   height: 100%;
 }

 .content {
   width: 1200px;
   margin: 23px auto;
 }

 .people_detail {
   padding:5px 0 20px 10px ;
   border-bottom: 1px solid #cccccc;
 }

 .title {
   font-size: 17px;
   margin-bottom: 40px;
 }

 .add_box {
   margin-bottom: 15px;
   display: inline-block;
   cursor: pointer;
   width: 150px;
   height: 150px;
   background-color:  $colorMain;
   color: #FFFFFF;
   text-align: center;
   padding-top: 40px;
 }

 .add_people {
   margin-top: 20px;
   width: 100%;
   text-align: center;
   border-bottom: 1px solid #cccccc;
    .el-form {
      display: flex;
      justify-content: space-around;
    }

    .el-form .el-form-item {
      width: 310px;
    }
 }


 .illness {
   width: 80%;
   text-align: left;
 }

 .illness_box {
   margin-bottom: 15px;
   margin-top: 10px;
   display: flex;
 }

 .illness_title {
   text-align: right;
   margin-right: 20px;
   padding-top: 6px;
   width: 10%;
 }

 .illness_btn {
   margin: 0 20px 15px 0;
   border: 1px solid #dcdfe6;
   border-radius: 4px;
   background-color: #fff;
   padding: 7px 17px;
 }

 .illness_btn_selected {
   background-color:  $colorMain;
   color: #FFFFFF;
 }

 .begin_to_treat {
   .el-form .el-form-item {
     display: block;
     width: 468px;
   }
   .el-textarea {
     width: 170%;
   }
 }

 .upload_count {
   font-size: 14px;
   margin-bottom: 10px;
 }

 .peoples {
   padding: 12px;
   display: inline-block;
   //background-color: #F5F5F5FF;
   height: 150px;
   width: 280px;
   margin:0 15px 15px 0;
   border: 1px solid #dfdfdf;
   position: relative;
 }

 .peoples div {
   margin-bottom: 8px;
   cursor: pointer;
 }
 .img_selected {
   width: 14px;
   height: 14px;
   position: absolute;
   right: 0;
   bottom: 0;
 }

 .people_box {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: wrap;
 }

 .tit {
   display: flex;
   justify-content: space-between;
 }

 .selected {
   border-color: red;
 }

 .clear:hover {
   background-color:  $colorMain;
   color: #FFFFFF;
 }

 .colors {
   background-color:  $colorMain;
 }

 .order_people {
   ::v-deep {
     .el-upload--picture-card:hover{
       border-color: $colorMain;
       color: #FFFFFF;
     }

     .el-radio__inner:hover {
       border-color: $colorMain;
     }

     .el-checkbox__input:hover {
       border-color: $colorMain;
     }
     .el-checkbox__input.is-checked .el-checkbox__inner {
       border-color: $colorMain;
       background: $colorMain;
     }

     .el-checkbox__input.is-checked+.el-checkbox__label {
       color: $colorMain;
     }

     .el-radio__input.is-checked .el-radio__inner {
       border-color: $colorMain;
       background: $colorMain;
     }

     .el-radio__input.is-checked+.el-radio__label {
       color: $colorMain;
     }
     .el-checkbox__inner {
       border-radius: 7px;
     }
     .el-button:hover {
       border: none !important;
       color: #FFFFFF !important;
       background-color: $colorMain !important;
     }
   }
 }

</style>